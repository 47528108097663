import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-four'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader/solutionsheader'
import ServicesArea from '../containers/it-solutions/services-area'
import ContactArea from '../containers/index-infotechno/contact-area'

const ITServicePage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO 
      title="Solutions" 
      description="We offer you open source and prebuilt solutions to accelerate your process and deploy your tech stack without starting from scratch."
    />
    <Header/>
    <PageHeader 
        pageContext={pageContext} 
        location={location}
        title="Solutions"
    />
    <main className="site-wrapper-reveal">
        <ServicesArea/>
        <ContactArea/>
    </main>
    <Footer/>
  </Layout>
)
 
export default ITServicePage
 